/* :root {
  --max-width: 1100px;
  --border-radius: 12px;
  --font-mono: ui-monospace, Menlo, Monaco, 'Cascadia Mono', 'Segoe UI Mono',
    'Roboto Mono', 'Oxygen Mono', 'Ubuntu Monospace', 'Source Code Pro',
    'Fira Mono', 'Droid Sans Mono', 'Courier New', monospace;

  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;

  --primary-glow: conic-gradient(
    from 180deg at 50% 50%,
    #16abff33 0deg,
    #0885ff33 55deg,
    #54d6ff33 120deg,
    #0071ff33 160deg,
    transparent 360deg
  );
  --secondary-glow: radial-gradient(
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );

  --tile-start-rgb: 239, 245, 249;
  --tile-end-rgb: 228, 232, 233;
  --tile-border: conic-gradient(
    #00000080,
    #00000040,
    #00000030,
    #00000020,
    #00000010,
    #00000010,
    #00000080
  );

  --callout-rgb: 238, 240, 241;
  --callout-border-rgb: 172, 175, 176;
  --card-rgb: 180, 185, 188;
  --card-border-rgb: 131, 134, 135;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;

    --primary-glow: radial-gradient(rgba(1, 65, 255, 0.4), rgba(1, 65, 255, 0));
    --secondary-glow: linear-gradient(
      to bottom right,
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0.3)
    );

    --tile-start-rgb: 2, 13, 46;
    --tile-end-rgb: 2, 5, 19;
    --tile-border: conic-gradient(
      #ffffff80,
      #ffffff40,
      #ffffff30,
      #ffffff20,
      #ffffff10,
      #ffffff10,
      #ffffff80
    );

    --callout-rgb: 20, 20, 20;
    --callout-border-rgb: 108, 108, 108;
    --card-rgb: 100, 100, 100;
    --card-border-rgb: 200, 200, 200;
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
}

a {
  color: inherit;
  text-decoration: none;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
} */

html{
  background-image: url("./static/images/bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  overflow: scroll;
}

#root{
  background-image: url("./static/images/bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0 20px;
}

body {
  margin: 0;
  background-image: url("./static/images/bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

section {
  min-height: 100vh;
  max-width: 1232px;
  margin: auto;
}

.container {
  background-image: url("./static/images/dnd-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  padding: 80px 69px;
  box-sizing: border-box;
  width: 100%;
  max-width: 1150px;
  min-height: 435px;
  margin: auto;
  background-color: white;
  border-radius: 10px;
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
    rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

@media only screen and (max-width: 860px) {
  .container {
    padding: 60px 20px;
  }
}

.drop-section {
  min-height: 70%;
  border: 1px dashed #8ecddd;
  background-color: #fff;
  border-radius: 10px;
  position: relative;
  padding: 106px 20px;
}

@media only screen and (max-width: 450px) {
  .drop-section {
    padding: 20px 20px;
  }
}

.drop-section-after-upload {
  min-height: 0 !important;
  border: 1px dashed #8ecddd;
  background-color: transparent !important;
  border-radius: 10px;
  position: relative;
  padding: 8px 24px !important;
  /* max-width: 582px; */
  margin-left: auto;
  margin-right: auto;
  margin-top: 25px;
}

.drop-section-after-upload .cloud-icon {
  margin: 0 !important;
}

.drop-section-after-upload .titleDnD,
.drop-section-after-upload .subTitleDnD {
  text-align: left !important;
  margin-bottom: 0 !important;
}

.drop-section-after-upload .col-after-upload {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.drop-section div.col:first-child {
  opacity: 1;
  visibility: visible;
  transition-duration: 0.2s;
  transform: scale(1);
  text-align: center;
  margin: auto;
}

.drop-section div.col:last-child {
  font-size: 40px;
  font-weight: 700;
  color: #c0cae1;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  width: 200px;
  height: 55px;
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
  transform: scale(0.6);
  transition-duration: 0.2s;
}

.col svg {
  margin-top: 5px;
}

.drag-over-effect div.col:first-child {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transform: scale(1.1);
}

.drag-over-effect div.col:last-child {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}

.drop-section .cloud-icon {
  margin-top: 25px;
  margin-bottom: 20px;
  display: inline-block;
}

.subTitleDnD {
  color: rgba(0, 0, 0, 0.4);
  font-size: 14px;
  margin-top: 12px;
  margin-bottom: 24px;
}

.file-selector {
  padding: 12px 40px;
  width: fit-content;
  height: 46px;
  background: #22668d;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.16);
  border-radius: 100px;
  font-family: "TWK Everett";
  font-style: normal;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.03em;
  color: #fffadd;
  border: none;
}

.drop-section-after-upload .file-selector {
  background-color: transparent !important;
  color: #ffcc70;
  border: 1px solid #ffcc70;
}

.drop-section input {
  display: none;
}

.list-section {
  display: none;
  text-align: left;
  margin: 30px auto;
  padding-bottom: 20px;
  /* max-width: 582px; */
  margin-bottom: 110px;
}

.list-section .list-title {
  font-size: 14px;
  color: #0f1d28;
}

.list-section li {
  display: flex;
  margin: 15px 0px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 8px;
  transition-duration: 0.2s;
  background-color: #ffffff72;
  justify-content: space-around;
}

.list-section li:hover {
  box-shadow: #e3eaf9 0px 0px 4px 0px, #e3eaf9 0px 12px 16px 0px;
  background-color: #fff;
}

.list-section li .col {
  flex: 0.1;
}

.list-section li .col:nth-child(1) {
  /* flex: 0.15; */
  text-align: center;
}

.list-section li .col:nth-child(2) {
  flex: 0.75;
  text-align: left;
  font-size: 0.9rem;
  color: #3e4046;
  padding: 8px 10px;
}

.list-section li .col:nth-child(2) div.name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 250px;
  display: inline-block;
}

.list-section li .col .file-name span {
  color: #707ea0;
  float: right;
}

.list-section li .file-progress {
  width: 100%;
  height: 5px;
  margin-top: 8px;
  border-radius: 8px;
  background-color: #dee6fd;
}

.list-section li .file-progress span {
  display: block;
  width: 0%;
  height: 100%;
  border-radius: 8px;
  background-image: linear-gradient(120deg, #6b99fd, #9385ff);
  transition-duration: 0.4s;
}

.list-section li .col .file-size {
  font-size: 0.75rem;
  margin-top: 3px;
  color: #707ea0;
}

.list-section li .col svg.cross,
.list-section li .col svg.tick {
  fill: #8694d2;
  background-color: #dee6fd;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
}

.list-section li .col svg.tick {
  fill: #50a156;
  background-color: transparent;
}

.list-section li.complete span,
.list-section li.complete .file-progress,
.list-section li.complete svg.cross {
  display: none;
}

.list-section li.in-prog .file-size,
.list-section li.in-prog svg.tick {
  display: none;
}

.wrapper-ater-log-in {
  max-width: 1158px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 150px;
}

.delete-btn {
  border: none;
  border-radius: 5px;
  padding: 8px 32px;
  background-color: #22668D;
  color: #f0f0f0;
  /* display: block; */
  /* margin-left: auto; */
  margin-bottom: 16px;
  margin-left: 12px;
}

.clear-filter-btn {
  border: 1px solid #22668d;
  border-radius: 5px;
  padding: 8px 32px;
  background-color: #22668d;
  color: #f0f0f0;
  /* display: block; */
  /* margin-left: auto; */
  margin-bottom: 16px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.16);
}

@font-face {
  font-family: "getvoip";
  src: local("getvoip"),
    url("/src/static/fonts/GetVoIPGrotesque.otf") format("opentype");
}

@font-face {
  font-family: "gallery";
  src: local("gallery"),
    url("/src/static/fonts/GalleryModern.otf") format("opentype");
}

@font-face {
  font-family: "everett-b";
  src: local("everett-b"),
    url("/src/static/fonts/TWKEverett-Bold.otf") format("opentype");
}

@font-face {
  font-family: "everett-m";
  src: local("everett-m"),
    url("/src/static/fonts/TWKEverett-Medium.otf") format("opentype");
}

@font-face {
  font-family: "everett-r";
  src: local("everett-r"),
    url("/src/static/fonts/TWKEverett-Regular.otf") format("opentype");
}

@font-face {
  font-family: "everett-u";
  src: local("everett-u"),
    url("/src/static/fonts/TWKEverett-Ultralight.otf") format("opentype");
}

.ff-getvoip {
  font-family: "getvoip";
}

.ff-gallery {
  font-family: "gallery";
}

.ff-everett-b {
  font-family: "everett-b";
}

.ff-everett-m {
  font-family: "everett-m";
}

.ff-everett-r {
  font-family: "everett-r";
}

.ff-everett-u {
  font-family: "everett-u";
}

.ff-everett-u-imp {
  font-family: "everett-u" !important;
}

.spinner {
  width: 30px;
  height: 30px;
  border: 2px solid grey;
  border-top: 2px solid black;
  border-radius: 50%;
  animation: spinAnime 1s linear infinite;

}

@keyframes spinAnime {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }

}


.column {
  width: 20em;
  float: left;
}

.column div {
  background: red;
  margin: 1em;
  overflow-wrap: break-word;
}