.getStartedBtn {
  border-radius: 100px;
  background-color: rgba(34, 102, 141, 0.85);
  color: #fff;
  border: none;
  padding: 14px 45px;
  font-family: 'everett-m';
  font-size: 1rem;
  letter-spacing: 0.5px;
  filter: drop-shadow(0px 4px 25px rgba(0, 0, 0, 0.08));
  cursor: pointer;
}