.signIn {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    margin-top: 4rem;
}

.features {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media only screen and (max-width: 950px) {
    .signIn {
        padding: 50px 20px;
    }

    .features {
        margin-right: 0;
        max-width: 518px;
    }

    .featuresLogo {
        /* display: none; */
    }
}

.featureTitle {
    font-family: "everett-m";
    font-size: 2.25rem;
    color: #22668d;
    max-width: 489px;
    margin-top: 24px;
    margin-bottom: 40px;
    text-transform: uppercase;
}

.featureItems {
    display: flex;
    color: #0f1d28;
    align-items: center;
    font-family: "everett-m";
    font-size: 1rem;
    margin-bottom: 24px;
}

.featureItems img {
    margin-right: 12px;
}

.singInCard {
    background-color: #fff;
    /* min-height: 693px; */
    min-width: 518px;
    filter: drop-shadow(0px 4px 25px rgba(0, 0, 0, 0.08));
    border-radius: 5px;
    padding: 40px 43px;
    box-sizing: border-box;
    color: #0f1d28;
}

.signInLogo {
    display: none;
}

.langbtn{
    margin-bottom: 32px;
    opacity: 0.5;
}

@media only screen and (max-width: 950px) {
    .singInCard {
        max-width: 518px;
        min-width: 100%;
    }

    .signInLogo {
        display: block;
        margin-bottom: 16px;
        width: 25%;
        margin-top: 0px;
    }

    .langbtn {
        position: relative;
        bottom: 32px;
    }
}

@media only screen and (max-width: 520px) {
    .singInCard {
        padding: 60px 30px;
    }
}

.signInTitle {
    font-family: "everett-m";
    font-size: 18px;
    margin-bottom: 36px;
    margin-top: 24px;
    opacity: 0.85;
}

.signInSubTitle {
    font-family: 'everett-r';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 30px;
    margin-top: -20px;
}

.signInSubTitle p{
    font-family: 'everett-r';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 2px;
    opacity: 0.45;
}

.signInSubTitle button {
    background-color: transparent;
    border: none;
    color: #4485C0;
    font-size: 15px;
    font-family: 'everett-u';
    cursor: pointer;
    padding: 0;
    opacity: 1;
    margin-top: 8px;
    margin-right: 16px;
}

.signInSubTitle button:hover {
    text-decoration: underline;
}


.singInCard label {
    font-family: 'everett-r';
    font-size: 16px;
    opacity: 1;
    display: block;
    margin-bottom: 12px;
}

.singInCard input {
    background-color: #fff !important;
    border: none;
    border-bottom: 1px solid rgba(15, 29, 40, 0.1);
    width: 100%;
    display: block;
    font-size: 15px;
    font-family: 'everett-r';
    margin-bottom: 25px;
    padding: 8px;
    border-radius: 4px 4px 0 0;
}

.singInCard input:focus,
.singInCard input:active,
.singInCard input:hover {
    background: transparent;
    border: none;
    outline: none;
    border-bottom: 1px solid rgba(15, 29, 40, 0.35);
}

.singInCard input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 50px white inset;
}

.singInCard input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 50px white inset;
}

.signInBtn {
    background-color: #FFCC70;
    border-radius: 10px;
    border: none;
    padding: 15px 55px;
    font-size: 14px;
    font-family: 'everett-r';
    margin: auto;
    display: block;
    margin-top: 25px;
    cursor: pointer;
    width: 100%;
}

.resetPwd {
    background-color: transparent;
    border: none;
    color: #4485C0;
    font-size: 14px;
    font-family: 'everett-u';
    cursor: pointer;
    position: absolute;
    bottom: 325px;
}

.orWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: rgba(15, 29, 40, 0.35);
    justify-content: center;
    margin: 25px 0;
}

.orHR {
    background-color: rgba(15, 29, 40, 0.15);
    width: 50px;
    height: 0.5px;
    margin: 0 20px;
}

.signInWithGoogle {
    width: 100%;
    background-color: #fff;
    border-radius: 100px;
    border: 1px solid rgba(15, 29, 40, 0.15);
    padding: 15px 0;
    font-family: 'everett-r';
    display: flex;
    justify-content: center;
    font-size: 14px;
    margin: auto;
}

.signInWithGoogle img {
    margin-right: 8px;
}

.inputsWrapper label{
    margin-bottom: 4px;
}