

.getStartedBtn {
  display: block;
  margin-left: auto;
  margin-bottom: 28px;
  border-radius: 10px;
  background-color: #FFCC70;
  color: #0F1D28;
  border: none;
  padding: 14px 45px;
  font-family: 'everett-m';
  font-size: 1rem;
  letter-spacing: 0.5px;
  filter: drop-shadow(0px 4px 25px rgba(0, 0, 0, 0.08));
  cursor: pointer;
}

a{
  text-decoration: none;
}


.popUpOverlay{
  min-height: 100vh;
  background-color: #0f1d2885;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100000000;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popUpWrapper{
  padding: 4rem 2rem;
  max-width: 768px;
  background-color: #fff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  width: 100%;
  margin: 0 8px;
  position: relative;
}

.popUpWrapper svg{
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  cursor: pointer;
}

.popUpWrapper p{
  font-family: 'everett-u';
  font-size: 24px;
  text-align: center;
  color: #0F1D28;
}

.popUpWrapper hr{
  background-color: #0F1D28;
  border: none;
  width: 10%;
  margin: 2rem auto;
  border-radius: 100px;
}

.signInBtn {
  background-color: #FFCC70;
  border-radius: 100px;
  border: none;
  padding: 15px 35px;
  font-size: 14px;
  font-family: 'everett-r';
  margin: auto;
  display: block;
  margin-top: 1rem;
  cursor: pointer;
  width: 50%;
}
