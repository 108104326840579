.DnDTitle {
  font-family: "getvoip";
  font-size: 24px;
  text-align: center;
  color: rgba(34, 102, 141, 0.8);
  position: relative;
  bottom: 30px;
}

.wrapperDiv{
  height: 689px;
}

@media only screen and (max-width: 450px) {
  .DnDTitle{
    font-size: 20px;
  }

  .loadingWrapperBar {
    min-height: 530px !important;
    bottom: 530px !important;
  }

  .wrapperDiv{
    height: 550px;
  }
}

.cancelBtn {
  padding: 12px 40px;
  background: #ffffff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  font-family: "everett-r";
  font-size: 15px;
  line-height: 20px;
  color: #b3c1cd;
  border: none;
  cursor: pointer;
 }
 
 
 .startBtn {
  padding: 12px 40px;
  background: #e7b253;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  font-family: "everett-r";
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #ffffff;
  border: none;
  margin-left: 8px;
  cursor: pointer;
 }
 
 
 .loadingWrapper {
  background-color: #fff;
  min-height: 623.3px;
  text-align: center;
  border-radius: 10px;
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
    rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  max-width: 1150px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 20;
 }
 
 
 
 
 
 
 .successTitle {
  font-family: "everett-r";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #0f1d28;
  margin-bottom: 16px;
 }
 
 
 .successSubTitle {
  width: 318px;
  font-family: "everett-r";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
 }
 
 
 .privacy {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
  margin-bottom: -32px;
  font-size: 12px;
  font-family: "everett-u";
  color: #0f1d28b4;
 }
 
 
 .privacy p {
  font-size: 12px;
  font-family: "everett-u";
  color: #0f1d28b4;
  margin-bottom: 2px;
 }
 
 
 .loadingWrapperBar {
  background-color: #fff;
  min-height: 623.3px;
  text-align: center;
  border-radius: 10px;
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
    rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  max-width: 1150px;
  margin: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  /* z-index: -1; */
  bottom: 644px;
}


.loadingBarWrapper {
  position: relative;
  max-width: 938px;
  background-color: #D9D9D9;
  height: 7px;
  border-radius: 100px;
  overflow: hidden;
  margin-left: 7.5%;
  margin-right: 7.5%;
 }
 
 
 .loadingBar{
  height: 7px;
  background-color: #549FE3;
  -webkit-transition: width 10s ease-in-out;
  -moz-transition: width 10s ease-in-out;
  -o-transition: width 10s ease-in-out;
  transition: width 10s ease-in-out;
  width: 2%;
}

.loadingBarWrapper div {
  position: absolute !important;
  top: 0;
  height: 7px !important;
  border-radius: 100px;
  /* background-color: #549FE3 !important;
  color: #549FE3 !important; */
 }
 
 
 .loadingText {
  font-family: "everett-u";
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #0F1D28;
  margin-bottom: 23px;
 }
 