.navigationWrapper{
    display: flex;
    flex-direction: row;
  }
  
  .sidebar{
    height: 100vh;
    box-sizing: border-box;
    width: 290px;
    border-right: 1px solid #fff;
    position: sticky;
    top: 0;
  }
  
  .sidebar button{
    background-color: transparent;
    display: block;
    border: none;
    color: #6C7A8C;
    font-size: 14px;
    font-family: 'everett-r';
    margin-bottom: 30px;
    cursor: pointer;
    /* padding-left: 28px; */
    display: flex;
    align-items: center;
  }
  
  .logo{
    margin: 22px 30px 78px 0;
  }
  
  .navbar{
    margin-bottom: 50px;
    padding: 12px 0px 12px 0px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #fff;
    align-items: center;
  }
  
  .hamburger{
  visibility: hidden;
  }
  
  .hamburger .hamburgerDivs{
  width: 36px;
  background-color: #22668D;
  height: 4px;
  border-radius: 100px;
  margin-bottom: 4px;
  cursor: pointer;
  }
  
  .pageNavBtn{
  margin-top: 36px;
  }

  .langAndSignOutBtn{
    z-index: 1000000000;
  }
  
  .langAndSignOutBtn .dropdown{
  margin-bottom: 20px;
  }
  
  .langAndSignOutBtn .signOut{
  margin-left: 0;
  }
  
  .pageNavBtn button{
  background-color: transparent;
  display: block;
  border: none;
  color: #6C7A8C;
  font-size: 15px;
  font-family: 'everett-r';
  margin-bottom: 30px;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  }
  
  .navRight{
  display: flex;
  flex-direction: row;
  }
  
  @media only screen and (max-width: 820px) {
  .hamburger{
    visibility: visible;
  }
  
  .sidebar{
    display: none;
  }
  
  .navRight{
    display: none;
  }
  }
  
  
  .dropbtn {
    background-color: transparent;
    color: #6C798F;
    padding: 12px;
    font-size: 16px;
    border: none;
    cursor: pointer;
    box-sizing: border-box;
    border: 1px solid #6c798f5f;
    border-radius: 10px;
    display: flex;
    align-items: center;
  }
  
  .dropbtn img{
    margin: 0 5px;
  }
  
  /* The container <div> - needed to position the dropdown content */
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  /* Dropdown Content (Hidden by Default) */
  .dropdownContent {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    border-radius: 10px;
    margin-top: 1px;
  }
  
  /* Links inside the dropdown */
  .dropdownContent button {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    border-radius: 10px;
    border: none;
    background-color: transparent;
    width: 100%;
  }
  
  /* Change color of dropdown links on hover */
  .dropdownContent button:hover {background-color: #dfdfdf}
  
  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdownContent {
    display: block;
  }
  
  /* Change the background color of the dropdown button when the dropdown content is shown */
  .dropdown:hover .dropbtn {
    background-color: #ffffff52;
  }
  
  .signOut{
    padding: 13px 18px;
    font-size: 16px;
    border: none;
    cursor: pointer;
    box-sizing: border-box;
    border: 1px solid rgba(255, 0, 8, 0.373);
    border-radius: 10px;
    display: flex;
    align-items: center;
    color: rgba(255, 0, 8, 1);
    background-color: transparent;
    margin-left: 15px;
  }
  
  .signOut:hover{
    color: #fff;
    background-color: rgba(255, 0, 8, 1);;
  }