.navbar {
  display: flex;
  /* width: 1231px; */
  justify-content: space-between;
  margin-top: 50px;
  margin-bottom: 75px;
}

.navLinks {
  display: flex;
  flex-direction: row;
  font-family: "everett-u";
  width: 600px;
  justify-content: space-between;
  display: none;
}

.LangAndSignInWrapper{
 display: flex;
 justify-content: flex-end;
}

@media only screen and (max-width: 450px) {
  .LangAndSignInWrapper{
    flex-direction: column;
  }

  .getStartedBtn{
    margin-top: 20px;
  }
}

.navLinks div {
  cursor: pointer;
}

.navLinks a {
  color: #0f1d28;
}

.getStartedBtn {
  border-radius: 100px;
  background-color: rgba(34, 102, 141, 0.85);
  color: #fff;
  border: none;
  padding: 14px 45px;
  font-family: "everett-m";
  font-size: 1rem;
  letter-spacing: 0.5px;
  filter: drop-shadow(0px 4px 25px rgba(0, 0, 0, 0.08));
  cursor: pointer;
}

.tutorial {
  display: flex;
  margin-bottom: 48px;
  flex-direction: row-reverse;
  align-items: center;
}

.tutorialImg {
  width: 40%;
  height: 40%;
}

@media only screen and (max-width: 830px) {
  .tutorial {
    flex-direction: column;
  }

  .tutorialImg {
    width: 75%;
    height: 75%;
    margin-bottom: 2rem;
  }
}

.steps {
  display: flex;
  padding: 32px 0;
  border-top: 1px solid rgba(15, 29, 40, 0.08);
}

.stepNumber {
  font-family: "everett-b";
  font-size: 20px;
  color: #0f1d28;
  opacity: 0.65;
  margin-right: 12px;
}

.stepText {
  font-family: "everett-r";
  font-size: 16px;
  color: #0f1d28;
  opacity: 0.85;
}

.stepText span {
  font-family: "everett-b";
}

.lastStep {
  border-bottom: 1px solid rgba(15, 29, 40, 0.08);
}

/* .featuresHeading {
  font-family: "everett-m";
  font-size: 30px;
  color: #0f1d28;
  display: inline-block;
  padding-bottom: 25px;
  border-bottom: 4px solid #22668d;
  border-radius: 3px;
  margin-bottom: 30px;
} */

.featuresText {
  font-family: "everett-r";
  font-size: 20px;
  color: #0f1d28;
  margin-bottom: 75px;
}

.tabs {
  width: 602px;
  display: flex;
  justify-content: space-between;
  margin: auto;
  margin-bottom: 55px;
}

.tab1btn {
  padding: 15px 59px;
  border-radius: 100px;
  background-color: #22668d;
  font-family: "everett-m";
  font-size: 14px;
  border: none;
  color: #f0f0f0;
  cursor: pointer;
}

.tab1btnUnselected {
  padding: 15px 59px;
  border-radius: 100px;
  background-color: transparent;
  font-family: "everett-m";
  font-size: 14px;
  border: none;
  color: #0f1d28;
  opacity: 0.65;
  cursor: pointer;
}

.tabContent {
  display: flex;
  margin-bottom: 150px;
}

.tabContent ul {
  margin: 0;
  padding: 0;
  padding-left: 15px;
  margin-right: 64px;
  font-family: "everett-u";
  font-size: 18px;
}

.tabContent ul span {
  font-family: "everett-b";
}

.featuresHeading2 {
  border-color: #8ecddd !important;
}

.featuresHeading3 {
  border-color: #ffcc70 !important;
}

.tab2btn {
  padding: 15px 59px;
  border-radius: 100px;
  background-color: #8ecddd;
  font-family: "everett-m";
  font-size: 14px;
  border: none;
  color: rgba(15, 29, 40, 0.85);
  cursor: pointer;
}

.tab3btn {
  padding: 15px 59px;
  border-radius: 100px;
  background-color: #ffcc70;
  font-family: "everett-m";
  font-size: 14px;
  border: none;
  color: rgba(15, 29, 40, 0.85);
  cursor: pointer;
}

.cta {
  max-width: 1000px;
  border: 4px solid #22668d;
  border-right: none;
  border-left: none;
  margin: auto;
  margin-bottom: 150px;
  background: url("../../static/images/cta-bg.svg") no-repeat 50% 50%;
  /* background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover; */
}

@media only screen and (max-width: 560px) {
  .cta{
    background-size: contain;
  }
}

.ctaText {
  font-family: "getvoip";
  font-size: 45px;
  color: #22668dcd;
  max-width: 600px;
  margin: auto;
  margin-top: 110px;
  margin-bottom: 50px;
  text-align: center;
  line-height: 60px;
}

.ctaText span {
  color: #22668d;
  text-transform: uppercase;
}

.ctaBtn {
  border: none;
  cursor: pointer;
  background-color: #22668d;
  color: #f0f0f0;
  padding: 25px 80px;
  margin: auto;
  font-family: "everett-b";
  border-radius: 5px;
  font-size: 22px;
  text-transform: uppercase;
  display: block;
  margin-bottom: 120px;
}

.footer {
  display: flex;
  justify-content: space-between;
}



.footerImg {
  flex-grow: 1;
}

.footerContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 2;
  margin-bottom: 80px;
}

.footerContentTitle {
  font-family: "everett-b";
  font-size: 16px;
  text-transform: uppercase;
  margin-bottom: 20px;
  color: #0f1d28c8;
}

.footerContent a {
  display: block;
  color: #0f1d2892;
  font-family: "everett-u";
  font-size: 16px;
  margin-bottom: 8px;
}

@media only screen and (max-width: 775px) {
  .footer{
    flex-direction: column;
  }

  .footerImg{
    margin-bottom: 2rem;
  }

  .footerContent{
    flex-wrap: wrap;
  }

  .footerContent div{
    width: 50%;
    margin-bottom: 1rem;
  }
}

.footerFooter {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #6c798f3c;
  padding-top: 20px;
  margin-bottom: 155px;
}

.copyright {
  font-family: "everett-u";
  font-size: 14px;
  color: #6C798F;
}

.dropbtn {
  background-color: transparent;
  font-family: "everett-u";
  color: #0f1d28;
  padding: 0;
  font-size: 16px;
  border: none;
  cursor: pointer;
  box-sizing: border-box;
  /* border: 1px solid #6c798f5f; */
  border-radius: 10px;
  display: flex;
  align-items: center;

  padding: 13px 42px;
  border: 1px solid #0f1d2852;
  border-radius: 100px;
  background-color: #ffffffd4;
  margin-right: 1rem;
}

.dropbtn img {
  margin: 0 5px 0 0;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdownContent {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 10px;
  margin-top: 1px;
  font-family: "everett-r";
  color: #0f1d28;
}

.dropdownContent button {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  border-radius: 10px;
  border: none;
  background-color: transparent;
  width: 100%;
}

.dropdownContent button:hover {
  background-color: #dfdfdf
}

.dropdown:hover .dropdownContent {
  display: block;
}

/* .dropdown:hover .dropbtn {
  background-color: #ffffff52;
} */

.loadingBarWrapper {
  position: relative;
  max-width: 938px;
  background-color: #D9D9D9;
  height: 7px;
  border-radius: 100px;
  overflow: hidden;
}

.loadingBarWrapper div {
  position: absolute !important;
  top: 0;
  height: 7px !important;
  border-radius: 100px;
  /* background-color: #549FE3 !important;
  color: #549FE3 !important; */
}

.heading {
  font-family: "everett-b";
  font-style: normal;
  font-size: 28px;
  line-height: 34px;
  color: #0F1D28;
  margin-top: 135px;
  margin-bottom: 35px;
}

.subHeading {
  font-family: "everett-r";
  font-style: normal;
  font-size: 18px;
  line-height: 22px;
  color: #0F1D28;
  margin-bottom: 45px;
}

.featuresWrapper {
  display: flex;
  margin-bottom: 135px;
  justify-content: space-between;
}

@media only screen and (max-width: 830px) {
  .featuresWrapper {
    flex-direction: column;
    align-items: center;

  }

  .feature {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin-bottom: 3rem;
  }
}

.feature {
  max-width: 500px;
}

.featuresHeading {
  font-family: "everett-b";
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
  color: rgba(15, 29, 40, 0.85);
  margin: 36px 0 16px;
}

.accWrapper {
  border-bottom: 1px solid #0f1d2833;
  margin-bottom: 135px;
}

.accHeader {
  background-color: transparent;
  border: none;
  padding: 42px 0;
  cursor: pointer;
  border-top: 1px solid #0f1d2833;
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-family: "everett-m";
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
  color: #0F1D28;
  align-items: center;
}

.accContent {
  font-family: "everett-r";
  font-style: normal;
  font-size: 18px;
  line-height: 22px;
  color: #0F1D28;
  margin-bottom: 42px;
}

.comment {
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  min-height: 111px;
  border: 1px solid #8ECDDD;
  padding: 15px 20px;
  margin-bottom: 22px;
  font-family: "everett-r";
  font-size: 18px;
  color: #0F1D28;
}

.comment:active,
.comment:focus {
  border: 1px solid #22668d;
  outline: none;
}

.comment::placeholder {
  color: #B3C1CD;
}

.commentBtn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 18px 117px;
  gap: 10px;
  background: #8ECDDD;
  border-radius: 100px;
  font-family: "everett-r";
  font-style: normal;
  font-size: 18px;
  line-height: 22px;
  color: #0F1D28;
  border: none;
  display: block;
  margin: 0 auto 135px;
}







