.feedbackCard{
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.15);
    margin: 1rem;
    margin-right: 0;
    padding: 1rem;
}

.feedbackTime{
    color: #0000007c;
    font-size: 14px;
    margin-top: 0.5rem;
}
.collapsedMessage {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  
  .expandedMessage {
    display: block;
  }
  

@media only screen and (max-width: 820px) {
    .feedbackCard{
        margin-left: 0;
        margin: 1rem 0;
    }
}