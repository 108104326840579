@font-face {
    font-family: "getvoip";
    src: local("getvoip"),
      url("https://cosbefonts.s3.amazonaws.com/GetVoIPGrotesque.otf")
        format("opentype");
  }
  
  @font-face {
    font-family: "gallery";
    src: local("gallery"),
      url("https://cosbefonts.s3.amazonaws.com/GalleryModern.otf")
        format("opentype");
  }
  
  @font-face {
    font-family: "everett-b";
    src: local("everett-b"),
      url("https://cosbefonts.s3.amazonaws.com/TWKEverett-Bold.otf")
        format("opentype");
  }
  
  @font-face {
    font-family: "everett-m";
    src: local("everett-m"),
    url("https://cosbefonts.s3.amazonaws.com/TWKEverett-Medium.otf")
    format("opentype");
  }
  
  @font-face {
    font-family: "everett-r";
    src: local("everett-r"),
    url("https://cosbefonts.s3.amazonaws.com/TWKEverett-Regular.otf")
        format("opentype");
  }
  
  @font-face {
    font-family: "everett-u";
    src: local("everett-u"),
    url("https://cosbefonts.s3.amazonaws.com/TWKEverett-Ultralight.otf")
        format("opentype");
  }
  
  .ff-getvoip {
    font-family: "getvoip";
  }
  
  .ff-gallery {
    font-family: "gallery";
  }
  
  .ff-everett-b {
    font-family: "everett-b";
  }
  
  .ff-everett-m {
    font-family: "everett-m";
  }
  
  .ff-everett-r {
    font-family: "everett-r";
  }
  
  .ff-everett-u {
    font-family: "everett-u";
  }
  
  .ff-everett-u-imp {
    font-family: "everett-u" !important;
  }